import React, {useState} from 'react';
import {
  FaCube,
  FaArrowUp,
  FaArrowDown,
  FaArrowLeft,
  FaArrowRight,
} from 'react-icons/fa';

interface CameraControlsProps {
  changeView: (view: 'top' | 'bottom' | 'left' | 'right' | 'initial') => void;
}

const CameraControls: React.FC<CameraControlsProps> = ({changeView}) => {
  const [activeView, setActiveView] = useState<
    'top' | 'bottom' | 'left' | 'right' | 'initial'
  >('initial');

  const handleViewChange = (
    view: 'top' | 'bottom' | 'left' | 'right' | 'initial',
  ) => {
    setActiveView(view);
    changeView(view);
  };

  return (
    <div className="absolute top-4 right-4 z-10">
      <div className="flex space-x-2">
        <button
          className={`btn btn-circle ${
            activeView === 'initial' ? 'btn-primary' : 'btn-ghost'
          }`}
          onClick={() => handleViewChange('initial')}>
          <FaCube />
        </button>
        <button
          className={`btn btn-circle ${
            activeView === 'top' ? 'btn-primary' : 'btn-ghost'
          }`}
          onClick={() => handleViewChange('top')}>
          <FaArrowUp />
        </button>
        <button
          className={`btn btn-circle ${
            activeView === 'bottom' ? 'btn-primary' : 'btn-ghost'
          }`}
          onClick={() => handleViewChange('bottom')}>
          <FaArrowDown />
        </button>
        <button
          className={`btn btn-circle ${
            activeView === 'left' ? 'btn-primary' : 'btn-ghost'
          }`}
          onClick={() => handleViewChange('left')}>
          <FaArrowLeft />
        </button>
        <button
          className={`btn btn-circle ${
            activeView === 'right' ? 'btn-primary' : 'btn-ghost'
          }`}
          onClick={() => handleViewChange('right')}>
          <FaArrowRight />
        </button>
      </div>
    </div>
  );
};

export default CameraControls;
