import React, {useRef, useState, useCallback, useEffect} from 'react';
import {useThree, useFrame, ThreeEvent} from '@react-three/fiber';
import {Html} from '@react-three/drei';
import * as THREE from 'three';
import {FaSync, FaTrash} from 'react-icons/fa';

interface InteractiveGLBModelProps {
  url: string;
  position: [number, number, number];
  rotation: [number, number, number];
  gltf: THREE.Group;
  onPositionChange: (newPosition: [number, number, number]) => void;
  isSelected: boolean;
  onSelect: () => void;
  setIsOrbitEnabled: (enabled: boolean) => void;
  onDelete: () => void;
  onDuplicate: () => void;
  onRotate: (axis: 'x' | 'z' | 'y') => void;
  callBlockPosition: (newPosition: [number, number, number]) => void;
}

const InteractiveGLBModel: React.FC<InteractiveGLBModelProps> = React.memo(
  ({
    url,
    position,
    rotation,
    gltf,
    onPositionChange,
    isSelected,
    onSelect,
    setIsOrbitEnabled,
    onDelete,
    onDuplicate,
    onRotate,
    callBlockPosition,
  }) => {
    const ref = useRef<THREE.Group>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const {camera, gl} = useThree();
    const [isDragging, setIsDragging] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    // const [isHovered, setIsHovered] = useState(false);
    const dragStart = useRef<THREE.Vector3>(new THREE.Vector3());
    const dragPlane = useRef<THREE.Plane>(new THREE.Plane());
    const clickStartTime = useRef<number>(0);
    // const [isDeleting, setIsDeleting] = useState(false);
    // const [blockPosition, setBlockPosition] = useState<[number, number, number]>(
    //   [0, 0, 0],
    // );
    const blockPositionRef = useRef<[number, number, number]>([0, 0, 0]);

    // Clone and enable transparency for materials
    useEffect(() => {
      gltf.traverse(child => {
        if ((child as THREE.Mesh).isMesh) {
          const mesh = child as THREE.Mesh;
          if (mesh.material) {
            if (Array.isArray(mesh.material)) {
              mesh.material = mesh.material.map(mat => {
                const clonedMat = mat.clone();
                clonedMat.transparent = isSelected;
                clonedMat.opacity = isSelected ? 0.3 : 1;
                // clonedMat.transparent = isHovered || isSelected;
                // clonedMat.opacity = isHovered || isSelected ? 0.3 : 1;
                return clonedMat;
              });
            } else {
              const clonedMat = mesh.material.clone();
              clonedMat.transparent = isSelected;
              clonedMat.opacity = isSelected ? 0.3 : 1;
              // clonedMat.transparent = isHovered || isSelected;
              // clonedMat.opacity = isHovered || isSelected ? 0.3 : 1;
              mesh.material = clonedMat;
            }
          }
        }
      });
    }, [isSelected, gltf]);

    const onPointerDown = useCallback(
      (e: ThreeEvent<PointerEvent>) => {
        e.stopPropagation();
        onSelect();
        clickStartTime.current = Date.now();

        if (ref.current) {
          dragStart.current.copy(ref.current.position);
          const normal = camera.getWorldDirection(new THREE.Vector3());
          dragPlane.current.setFromNormalAndCoplanarPoint(
            normal,
            ref.current.position,
          );
        }

        gl.domElement.addEventListener('pointermove', onPointerMove);
        gl.domElement.addEventListener('pointerup', onPointerUp);
      },
      [onSelect, gl, camera],
    );

    const onPointerMove = useCallback(
      (e: PointerEvent) => {
        if (Date.now() - clickStartTime.current > 200) {
          setIsDragging(true);
          setIsOrbitEnabled(false);
          setShowMenu(false);
          gl.domElement.style.cursor = 'grabbing';
        }
      },
      [setIsOrbitEnabled, gl],
    );

    const onPointerUp = useCallback(
      (e: PointerEvent) => {
        gl.domElement.removeEventListener('pointermove', onPointerMove);
        gl.domElement.removeEventListener('pointerup', onPointerUp);
        setIsOrbitEnabled(true);
        setIsDragging(false);
        if (isDragging) {
          callBlockPosition(blockPositionRef.current);
          gl.domElement.style.cursor = 'auto';
        } else if (Date.now() - clickStartTime.current < 200) {
          setShowMenu(prev => !prev);
        }
      },
      [isDragging, setIsOrbitEnabled, gl],
    );

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          menuRef.current &&
          !menuRef.current.contains(event.target as Node) &&
          ref.current &&
          !ref.current.getObjectByProperty(
            'uuid',
            (event.target as HTMLElement).dataset.uuid,
          )
        ) {
          setShowMenu(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const handleOptionClick = useCallback(
      (action: 'delete' | 'duplicate' | 'rotateX' | 'rotateY' | 'rotateZ') => {
        switch (action) {
          case 'delete':
            onDelete();
            break;
          case 'duplicate':
            onDuplicate();
            break;
          case 'rotateX':
            onRotate('x');
            break;
          case 'rotateY':
            onRotate('y');
            break;
          case 'rotateZ':
            onRotate('z');
            break;
        }
        setShowMenu(false);
      },
      [onDelete, onDuplicate, onRotate],
    );

    useFrame(({raycaster, mouse}) => {
      if (isDragging && ref.current) {
        raycaster.setFromCamera(mouse, camera);
        const intersection = new THREE.Vector3();

        if (raycaster.ray.intersectPlane(dragPlane.current, intersection)) {
          const newPosition: [number, number, number] = [
            Math.round(intersection.x),
            Math.round(intersection.y),
            Math.round(intersection.z),
          ];
          onPositionChange(newPosition);
          blockPositionRef.current = newPosition;
          // setBlockPosition(newPosition);
        }
      }
    });

    return (
      <group
        ref={ref}
        position={position}
        rotation={rotation}
        onPointerDown={onPointerDown}
        // onPointerOver={() => setIsHovered(true)}
        // onPointerOut={() => setIsHovered(false)}
      >
        <primitive object={gltf} /> {/* Use the gltf prop */}
        {showMenu && !isDragging && (
          <Html position={[0, 2, 0]}>
            <div
              className="flex space-x-2"
              ref={menuRef}
              onClick={e => e.stopPropagation()}>
              <button
                className="w-8 h-8 bg-white rounded-full flex items-center justify-center shadow-md"
                onClick={() => handleOptionClick('rotateX')} // Vertical Rotation
                title="Rotate X">
                <FaSync className="text-gray-600 transform rotate-90" />
              </button>
              <button
                className="w-8 h-8 bg-white rounded-full flex items-center justify-center shadow-md"
                onClick={() => handleOptionClick('rotateY')} // Horizontal Rotation
                title="Rotate Y">
                <FaSync className="text-gray-600 transform -rotate-90" />{' '}
                {/* Changed icon rotation */}
              </button>

              {/* <button
                className="w-8 h-8 bg-white rounded-full flex items-center justify-center shadow-md hide"
                onClick={() => handleOptionClick('duplicate')}
                title="Duplicate">
                <FaCopy className="text-gray-600" />
              </button> */}

              <button
                className="w-8 h-8 bg-white rounded-full flex items-center justify-center shadow-md"
                onClick={() => handleOptionClick('delete')}
                title="Delete">
                <FaTrash className="text-gray-600" />
              </button>
            </div>
          </Html>
        )}
      </group>
    );
  },
);

export default InteractiveGLBModel;
