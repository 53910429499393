import React from 'react';
import {Canvas} from '@react-three/fiber';
import GLBModel from './GLBModel';

interface PartListProps {
  parts: {
    filename: string;
    quantity: number;
  }[];
  inventory: {[key: string]: number};
  onPartClick: (filename: string) => void;
}

const PartList: React.FC<PartListProps> = React.memo(
  ({parts, inventory, onPartClick}) => {
    return (
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-white rounded-full shadow-lg py-2 px-4">
        <div className="flex space-x-4">
          {parts.map((piece, index) => {
            const remainingQuantity = inventory[piece.filename] || 0;
            const isDisabled = remainingQuantity === 0;
            return (
              <div key={index} className="relative">
                <button
                  className={`w-20 h-20 bg-gray-100 rounded-full overflow-hidden flex-shrink-0 ${
                    isDisabled ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  onClick={() => !isDisabled && onPartClick(piece.filename)}
                  disabled={isDisabled}>
                  <Canvas camera={{position: [4, 5, 5], fov: 80}}>
                    <ambientLight intensity={0.2} />
                    <pointLight position={[10, 10, 10]} />
                    <GLBModel
                      url={piece.filename}
                      filename={piece.filename}
                      animation={true}
                      position={[0, 0, 0]}
                    />
                  </Canvas>
                </button>
                <div className="absolute bottom-0 right-0 bg-blue-500 text-white text-xs font-bold rounded-full w-6 h-6 flex items-center justify-center shadow-md z-10 transform translate-x-1/4 translate-y-1/4">
                  {remainingQuantity}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);

export default PartList;
