export const GRID_UNIT = 1;
export const STUD_SIZE = 0.25 * GRID_UNIT;
export const BRICK_HEIGHT = 0.3 * GRID_UNIT;
export const PLATE_HEIGHT = 0.1 * GRID_UNIT;

export const LEGO_COLORS = [
  '#FF0000', // Bright Red
  '#FFD700', // Bright Yellow
  '#1E90FF', // Bright Blue
  '#32CD32', // Lime Green
  '#FFA500', // Orange
  '#8A2BE2', // Blue Violet
  '#00CED1', // Dark Turquoise
  '#FF1493', // Deep Pink
];
