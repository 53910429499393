import React from 'react';
import ReactDOM from 'react-dom/client';
import Editor3D from './components/Editor3D';
import './index.css'; // Add this line

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <Editor3D />,
  // </React.StrictMode>,
);
